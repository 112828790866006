/*
 * @Author: LZH
 * @Date: 2025-03-04 12:09:18
 * @LastEditors: Do not edit
 * @LastEditTime: 2025-03-12 17:47:49
 * @Description: 
 */
export default [    //电子围栏
    {   
        path: "/enclosure/TransboundaryRecord",  
        name:'TransboundaryRecord',
        meta: { title: "越界记录",navId:2 },
        component:() => import("@/views/enclosure/TransboundaryRecord.vue")
    },
    {   
        path: "/enclosure/googleMap",  
        name:'enclosureMap',
        meta: { navId:2 },
        component:() => import("@/views/intercom/googleMap.vue")
    }
]

