/*
 * @Author: LZH
 * @Date: 2025-03-04 12:09:18
 * @LastEditors: Do not edit
 * @LastEditTime: 2025-03-11 11:23:40
 * @Description: 
 */
export default [    //调度对讲
    {
        path: "/intercom/trajectory",  
        name:'trajectory',
        meta: { title: "芯对讲平台", navId:1 },
        component:() => import("@/views/intercom/trajectory.vue")
    },
    {
        path: "/intercom/googleMap",  
        name:'googleMap',
        meta: { title: "芯对讲平台",navId:1},
        component:() => import("@/views/intercom/googleMap.vue")
    }
]